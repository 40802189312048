<template>
  <b-card class="border border-dark shadow-none">
    <b-card-text>
      <b-container>
        <b-row>
          <b-col md="12">
            <h5 class="font-weight-bolder">
              Información del producto
            </h5>
          </b-col>
          <b-col
            md="12"
            class="my-1"
          >
            <TitleDetails
              :name="product.Name"
              :type="product.Type"
              :count="product.Qty"
              :save-route="false"
            />
          </b-col>
          <b-col
            v-if="$ability.can('read', 'Product_images')"
            md="12 mb-1"
          >
            <div class="col row align-items-center">
              <b-img
                v-for="(image, index) in product.images.middle"
                :key="index"
                left
                class="mr-1"
                height="140"
                :src="image.image"
              />
              <span
                v-if="product.images.small.some((item) => item.image.includes('/pending/'))"
                class="d-block text-center"
              >
                <feather-icon
                  icon="AlertTriangleIcon"
                />
                <br>
                Imagen pendiente
              </span>
            </div>
          </b-col>
          <b-col
            v-if="$ability.can('read', 'Product_brand')"
            md="3"
          >
            <InformationBasicCard
              title="Marca"
              :tags="[{ value: product.name_brand, type: 'string' }]"
            />
          </b-col>
          <b-col
            v-if="product.Type === 'Nuevo' && $ability.can('read', 'Product_item')"
            md="3"
          >
            <InformationBasicCard
              title="Item"
              :tags="[{ value: product.Item, type: 'string' }]"
            />
          </b-col>
          <b-col
            v-if="$ability.can('read', 'Product_color_paint')"
            md="3"
          >
            <InformationBasicCard
              title="Color pintura"
              :tags="[{ name: product.NamePaint, value: product.CodePaint, type: 'color' }]"
            />
          </b-col>
          <b-col
            v-if="$ability.can('read', 'Product_low_inventory')"
            md="3"
          >
            <InformationBasicCard
              title="Bajo inventario"
              :tags="[{ value: product.QtyLowInventory, type: 'string' }]"
            />
          </b-col>
          <b-col
            v-if="$ability.can('read', 'Product_model')"
            md="3"
          >
            <InformationBasicCard
              title="Modelo"
              :tags="[{ value: product.Model, type: 'string' }]"
            />
          </b-col>
          <b-col
            v-if="$ability.can('read', 'Product_code')"
            md="3"
          >
            <InformationBasicCard
              title="Código interno"
              :tags="[{ value: product.Code, type: 'string' }]"
            />
          </b-col>
          <b-col
            v-if="$ability.can('read', 'Product_color_tapestry')"
            md="3"
          >
            <InformationBasicCard
              title="Color de tapiz"
              :tags="[{ name: product.NameTapestry, value: product.CodeTapestry, type: 'color' }]"
            />
          </b-col>
          <b-col
            v-if="product.Type === 'Nuevo'"
            md="3"
          >
            <InformationBasicCard
              title="Proveedor"
              :tags="[{ value: product.NameSupplier, type: 'string' }]"
            />
          </b-col>
          <b-col
            v-if="$ability.can('read', 'Product_category')"
            md="3"
          >
            <InformationBasicCard
              title="Categoría y subcategoría"
              :tags="[{ value: `Categoría: ${product.TrueCategory ? product.TrueCategory.Name : 'Sin categoría'}`, type: 'string' },
                      { value: `Subcategoría: ${product.name_categorie}`, type: 'string' }]"
            />
          </b-col>
          <b-col
            v-if="$ability.can('read', 'Product_destiny')"
            md="3"
          >
            <InformationBasicCard
              title="Destino"
              :tags="[{ value: product.Destiny, type: 'string' }]"
            />
          </b-col>
          <b-col
            v-if="$ability.can('read', 'Product_sat')"
            md="3"
          >
            <InformationBasicCard
              title="Clave SAT"
              :tags="[{ value: product.keyCodeSat, type: 'string' }]"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BContainer, BImg,
} from 'bootstrap-vue'

import TitleDetails from '@/modules/production/products/components/product-card/TitleDetails.vue'
import InformationBasicCard from '@/components/cards/InformationBasicCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BCardText,
    BContainer,
    TitleDetails,
    InformationBasicCard,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
}
</script>
