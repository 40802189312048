<template>
  <b-card
    no-body
    class="border border-dark shadow-none"
  >
    <b-card-header class="px-1 mt-0 ml-2">
      <b-row>
        <b-col md="12">
          <h5 class="font-weight-bolder">
            Información de las piezas
          </h5>
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body
      v-if="pieces && pieces.length > 0"
      class="px-0 py-0"
    >
      <slot>
        <PiecesList
          :show-invoice-column="true"
          :pieces="pieces"
          :hide-prices="hidePrices"
        />
      </slot>
    </b-card-body>
    <b-card-body v-else>
      <b-alert
        variant="warning"
        show
      >
        <div class="alert-body">
          <span>No se encontraron piezas.</span>
        </div>
      </b-alert>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BAlert, BCardBody, BCardHeader,
} from 'bootstrap-vue'

import PiecesList from '@/modules/production/products/components/pieces/PiecesList.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    PiecesList,
    BCardHeader,
    BCardBody,
  },
  props: {
    pieces: {
      type: Array,
      required: true,
    },
    hidePrices: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
