<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col
        xl="9"
        lg="10"
        md="12"
      >
        <template v-if="isLoadingProducts">
          <BasicSkeleton />
          <BasicSkeleton height="500px" />
          <BasicSkeleton height="100px" />
          <BasicSkeleton height="200px" />
          <BasicSkeleton height="400px" />
          <BasicSkeleton height="100px" />
        </template>
        <template v-if="!isLoadingProducts">
          <div class="mb-2">
            <DetailsOptions
              :product="product"
              :warehouse="true"
              :return="'incomes'"
            />
          </div>
          <ExtendedDetails :product="product" />
          <ProductDescriptionCard
            :product="product"
          />
          <PricesListCard
            v-if="$ability.can('read', 'Product_prices_list')"
            :product="product"
          />
          <PiecesListCard
            :pieces="product.pieces"
            :hide-prices="this.$ability.cannot('create', 'Product')"
          />
          <DimentionsProductCard
            v-if="$ability.can('read', 'Product_dimensions')"
            :product="product"
          />
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BRow, BCol, BContainer,
} from 'bootstrap-vue'

import PricesListCard from '@/modules/production/products/components/PricesListCard.vue'
import PiecesListCard from '@/modules/production/products/components/PiecesListCard.vue'
import DetailsOptions from '@/modules/production/products/components/product-card/DetailsOptions.vue'
import DimentionsProductCard from '@/modules/production/products/components/DimentionsProductCard.vue'
import ExtendedDetails from '@/modules/production/products/components/product-card/ExtendedDetails.vue'
import ProductDescriptionCard from '@/modules/production/products/components/ProductDescriptionCard.vue'

import BasicSkeleton from '@/components/cards/BasicSkeleton.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getError from '@/helpers/ErrorsHandler'

export default {
  name: 'IncomesDetails',
  components: {
    BCol,
    BRow,
    BContainer,
    BasicSkeleton,
    PricesListCard,
    PiecesListCard,
    DetailsOptions,
    ExtendedDetails,
    DimentionsProductCard,
    ProductDescriptionCard,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    query: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      product: [],
      isLoadingProducts: true,
    }
  },
  computed: {
    // ...mapState('products', ['product', 'isLoadingProducts']),
    ...mapGetters({
      getProductPiecesQuery: 'products/getProductPiecesQuery',
    }),
    productPiecesQuery: {
      get() { return this.getProductPiecesQuery },
      set(value) { this.setProductPiecesQuery(value) },
    },
  },
  async created() {
    try {
      const { data } = await this.loadProduct({ id: this.id })
      // eslint-disable-next-line prefer-destructuring
      const [product] = data.data.product
      this.product = product
      this.isLoadingProducts = false
    } catch (error) {
      this.showToast('Error de validación', getError(error), 'danger')
    } finally {
      this.$swal.close()
    }
  },
  methods: {
    ...mapActions({
      loadProduct: 'incomes/loadProductIncome',
      setProductPiecesQuery: 'products/setProductPiecesQuery',
    }),
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
