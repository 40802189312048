<template>
  <b-card class="border border-dark shadow-none">
    <b-card-text>
      <b-container>
        <b-row>
          <b-col md="12">
            <h5
              v-if="!product.PricesDefined"
              class="font-weight-bolder"
            >
              Precios de lista
            </h5>
          </b-col>

          <!-- #region::List of prices when DEFINED -->
          <template v-if="!product.PricesDefined">

            <!-- #region::Prices when NEW product -->
            <template v-if="product.Type === 'Nuevo' && $ability.can('read', 'Product_prices_list')">
              <b-col
                v-if="product.PriceMin"
                md="4"
              >
                <InformationBasicCard
                  title="Precio mínimo"
                  :tags="[{ value: product.PriceMin, type: 'currency' }]"
                />
              </b-col>
              <b-col
                v-if="product.PriceMax"
                md="4"
              >
                <InformationBasicCard
                  title="Precio máximo"
                  :tags="[{ value: product.PriceMax, type: 'currency' }]"
                />
              </b-col>
              <b-col
                v-if="product.OfferPrice"
                md="4"
              >
                <InformationBasicCard
                  title="Precio oferta"
                  :tags="[{ value: product.OfferPrice, type: 'currency' }]"
                />
              </b-col>
              <template v-if="product.PricesDefinedWholesaler && $ability.can('read', 'Product_prices_wholesaler')">
                <div
                  v-if="product.PriceMin && product.PriceMax"
                  class="w-100"
                >
                  <hr>
                </div>
                <b-col md="12">
                  <h5 class="font-weight-bolder">
                    {{ product.PriceMin && product.PriceMax ? 'Mayorista' : 'Precio de mayorista' }}
                  </h5>
                </b-col>
                <b-col md="4">
                  <InformationBasicCard
                    title="Precio mínimo"
                    :tags="[{ value: product.PriceMinWholesaler, type: 'currency' }]"
                  />
                </b-col>
                <b-col md="4">
                  <InformationBasicCard
                    title="Precio máximo"
                    :tags="[{ value: product.PriceMaxWholesaler, type: 'currency' }]"
                  />
                </b-col>
              </template>
            </template>
            <!-- #endregion::Prices when NEW product -->

            <!-- #region::Prices when USED product -->
            <template v-else-if="product.Type === 'Usado' && $ability.can('read', 'Product_prices_list')">
              <b-col md="3">
                <InformationBasicCard
                  title="Precio L/F"
                  :tags="[{ value: product.PriceLf, type: 'currency' }]"
                />
              </b-col>
              <b-col md="3">
                <InformationBasicCard
                  title="Precio D/R"
                  :tags="[{ value: product.PriceDr, type: 'currency' }]"
                />
              </b-col>
              <b-col md="3">
                <InformationBasicCard
                  title="Precio máximo"
                  :tags="[{ value: product.MaxSuggestedPrice, type: 'currency' }]"
                />
              </b-col>
              <b-col
                v-if="product.OfferPrice"
                md="3"
              >
                <InformationBasicCard
                  title="Precio oferta"
                  :tags="[{ value: product.OfferPrice, type: 'currency' }]"
                />
              </b-col>
            </template>
            <!-- #endregion::Prices when USED product -->

            <!-- #region::Prices when REMANOFACTURED product -->
            <template v-else-if="product.Type === 'Remanufacturado' && $ability.can('read', 'Product_prices_list')">
              <b-col
                v-if="product.PriceMin"
                md="3"
              >
                <InformationBasicCard
                  title="Precio mínimo"
                  :tags="[{ value: product.PriceMin, type: 'currency' }]"
                />
              </b-col>
              <b-col
                v-if="product.PriceMax"
                md="3"
              >
                <InformationBasicCard
                  title="Precio máximo"
                  :tags="[{ value: product.PriceMax, type: 'currency' }]"
                />
              </b-col>
              <b-col
                v-if="product.OfferPrice"
                md="4"
              >
                <InformationBasicCard
                  title="Precio oferta"
                  :tags="[{ value: product.OfferPrice, type: 'currency' }]"
                />
              </b-col>
            </template>
            <!-- #endregion::Prices when REMANOFACTURED product -->

          </template>
          <!-- #endregion::List of prices when DEFINED -->

          <!-- #region::List of prices when NO DFINED -->
          <template v-else>
            <b-col
              cols="12"
              class="d-flex justify-content-center"
            >
              <b-button
                variant="dark"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="DollarSignIcon" />
              </b-button>
            </b-col>
            <b-col
              cols="12"
              class="d-flex justify-content-center mt-1"
            >
              <h5 class="text-dark">
                <strong>Precio por definir</strong>
              </h5>
            </b-col>
          </template>
          <!-- #endregion::List of prices when NO DFINED -->
        </b-row>
      </b-container>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BContainer, BButton,
} from 'bootstrap-vue'

import InformationBasicCard from '@/components/cards/InformationBasicCard.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BContainer,
    BButton,
    InformationBasicCard,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    mainProps: {
      blank: true,
      blankColor: '#777',
      width: 40,
      height: 40,
      class: 'm1',
    },
  }),
}
</script>
