<template>
  <b-card class="border border-dark shadow-none">
    <b-card-text>
      <b-container>
        <b-row>
          <b-col md="12">
            <h5
              class="font-weight-bolder"
            >
              Descripción del producto
            </h5>
          </b-col>

          <template>
            <!-- #region::Description of product when DEFINED -->
            <template v-if="product.Description">
              <b-col
                md="12"
              >
                <span>{{ product.Description }}</span>
              </b-col>
            </template>
            <!-- #endregion::Description of product when DEFINED -->
            <!-- #region::Description of product when NO DEFINED -->
            <template v-else>
              <b-col
                class="text-center"
                md="12"
              >
                <feather-icon
                  icon="FileTextIcon"
                  size="60"
                />
                <h6
                  class="font-weight-bolder mt-2"
                >
                  Sin descripción del producto
                </h6>
              </b-col>
            </template>
            <!-- #endregion::Description of product when NO DEFINED -->
          </template>
        </b-row>
      </b-container>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BContainer,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BContainer,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    mainProps: {
      blank: true,
      blankColor: '#777',
      width: 40,
      height: 40,
      class: 'm1',
    },
  }),
}
</script>
