<template>
  <b-container>
    <b-row align-v="center">

      <!-- #region::Return product list link -->
      <b-col
        cols="12"
        class="px-0 mb-1"
      >
        <small v-if="!warehouse">
          <router-link
            :to="originProductRoute || { name: 'products-home' }"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              class="mr-50 text-secondary"
            />
            <span class="align-middle text-secondary">Regresar</span>
          </router-link>
        </small>
        <small v-else>
          <router-link
            :to="returnWarehouse"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              class="mr-50 text-secondary"
            />
            <span class="align-middle text-secondary">Regresar</span>
          </router-link>
        </small>
      </b-col>
      <!-- #endregion::Return product list link -->

      <b-col class="px-0">
        <h3>Detalles del producto</h3>
      </b-col>

      <!-- #region::Edit product button -->
      <b-col
        cols="12"
        md="auto"
        class="px-0 mr-1"
      >
        <b-button
          v-if="$ability.can('edit', 'Product') && !warehouse"
          variant="principal-btn"
          class="my-1 my-lg-0 principal-btn"
          @click="$router.push({ name: 'update-product', params: { id: product.IdProduct, visible: visible }})"
        >
          <feather-icon
            icon="Edit3Icon"
            class="mr-50"
          />
          <span class="align-middle">Editar</span>
        </b-button>
      </b-col>
      <!-- #endregion::Edit product button -->

      <TransferPiecesButton
        v-if="$ability.can('delete', 'Product') && !warehouse"
        :product="product"
      />
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BRow, BCol, BButton, BContainer,
} from 'bootstrap-vue'

import TransferPiecesButton from '@/modules/production/products/components/modals/TransferPiecesButton.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BContainer,
    TransferPiecesButton,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    warehouse: {
      type: Boolean,
      default: false,
    },
    return: {
      type: String,
      default: '',
    },
    visible: {
      type: [String, Number],
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      getOriginProductRoute: 'products/getOriginProductRoute',
    }),
    originProductRoute: {
      get() { return this.getOriginProductRoute },
    },
    returnWarehouse: {
      get() { return { name: this.return } },
    },
  },
}
</script>
