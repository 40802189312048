<template>
  <b-card class="border border-dark shadow-none">
    <b-card-text>
      <b-container>
        <b-row>
          <b-col md="12">
            <h5 class="font-weight-bolder">
              Dimensiones del producto
            </h5>
          </b-col>
          <b-col md="3">
            <InformationBasicCard
              title="Peso del producto"
              :tags="[{ value: product.Weight, append: product.TypeWeight, type: 'string' }]"
            />
          </b-col>
          <b-col md="3">
            <InformationBasicCard
              title="Largo del producto"
              :tags="[{ value: product.Length, append: 'cm', type: 'string' }]"
            />
          </b-col>
          <b-col md="3">
            <InformationBasicCard
              title="Ancho del producto"
              :tags="[{ value: product.Width, append: 'cm', type: 'string' }]"
            />
          </b-col>
          <b-col md="3">
            <InformationBasicCard
              title="Altura del producto"
              :tags="[{ value: product.Heigh, append: 'cm', type: 'string' }]"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BContainer,
} from 'bootstrap-vue'

import InformationBasicCard from '@/components/cards/InformationBasicCard.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BContainer,
    InformationBasicCard,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
}
</script>
